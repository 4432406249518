.home_cont
{
    height: 95vh;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.home_bg
{
    width: 100%;
    z-index: -1;
    object-fit: cover;
}

.home_screen
{
    width: 100%;
    height: 90vh;
    background-color: black;
}

.home_sub
{
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 90vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.home_bttn
{
    background-color: black;
    color: white;
    margin-top: 30px;
    padding: 25px;
    border-radius: 4px;
    border: 0px;
    width: 150px;

}

.home_bttn:hover
{
    transition: 700ms;
    transform: scale(1.1);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.home_tag1
{
    font-size: 55px;
    font-weight: 900;
    margin: 0%;
}

.home_tag2
{
    margin: 20px;
    font-size: 40px;
    font-weight: 300;
}


.homesubpara2wrap
{
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    background-color: black;
}

.homesubpara2
{
    background-color: black;
    color: whitesmoke;
    font-size: 15px;
    display: flex;
    gap: 90px;
    white-space: nowrap;
    animation: move-rt1 15000ms linear infinite;
    margin-right: 50px;
}
.homesubpara2>p
{
    margin-right: 50px;
    font-style: italic;
}

@keyframes move-rt1
{
    0%{
        transform: translateX(100);
    }
    100%
    {
        transform: translateX(-100%);
    }
}