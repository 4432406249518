


.wel_cont
{
    width: 100%;
    background-color: black;
    padding-top: 100px;
    padding-bottom: 100px;
}

.wel_img_sec
{
    width: 700px;
    height: 500px;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    overflow: hidden;
}

.wel_img
{
    height: 100%;
    object-fit: cover;
}

.wel_sec1
{
    display: flex;
    width: 100%;
}

.wel_desc
{
    color: white;
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10%;
    text-align: left;
}

.wel_desc>p
{
    font-size: 15px;
    font-weight: 300;
}


.card_sec
{
    padding-top: 100px;
    padding-bottom: 100px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 35%);
    color: white;
    justify-content: center;
    align-items: center;
    gap: 10%;
}

.card 
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10%;
    text-align: justify;
}

.card>p
{
    font-size: 15px;
    font-weight: 300;
}