

.cont_img_sec
{
    margin-top: 100px;
    width: 700px;
    height: 500px;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;
    overflow: hidden;
}

.cont_img
{
    height: 100%;
    object-fit: cover;
}





.cont_sec1
{
    display: flex;
    width: 100%;
}

.cont_desc
{
    color: black;
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10%;
    text-align: center;
}

.cont_desc>p
{
    font-size: 18px;
    font-weight: 300;
}


#form_cont
{
    text-align: center;
    margin-top: 20px;
}

#form_cont>input
{
    margin-top: 20px;
    width: 80%;
    padding: 5%;
    
}
