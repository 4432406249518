.ad_cont
{
    width: 100%;
    height: 130vh;
    position: relative;
}

.ad_sec1
{
    height: 100%;
}

.sec1_1
{
    height: 50%;
    width: 100%;
    background-color: black;
}

.sec1_2
{
    height: 50%;
    width: 100%;
    background-color: white;
}


.ad_sec2
{
    position: absolute;
    top: 0%;
    left: 20%;
    color: white;
    display: grid;
    width: 80%;
    margin: auto;

}

.ad_img1_cont
{
    height: 600px;
    width: 400px;
    border-radius: 25px;
    overflow: hidden;
}

.ad_img1
{
    height: 100%;
    object-fit: cover;
}

.ad_img2
{
    height: 100%;
    object-fit: cover;
}

.ad_img2_cont
{
    margin-top: -200px;
    height: 600px;
    width: 400px;
    border-radius: 25px;
    overflow: hidden;
}

.ad_sec2_text_cont
{
    width: 35%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ad_sec_sub
{
    display: flex;
    gap: 80px;
}