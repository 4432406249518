.about_cont
{
    padding-top: 80px;
    padding-bottom: 80px;
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.about_sec
{
    width: 300px;

}

.abot_img_sec
{
    width: 300px;
    height: 400px;
    border-radius: 25px;
    overflow: hidden;

}

.about_img
{
    width: 100%;
    object-fit: cover;
}

.about_desc
{
    text-align: left;
    padding: 10px;
}

.about_desc_para
{
    text-align: justify;
    font-size: 13px;
    font-weight: 300;
}