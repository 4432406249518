.navbar_cont
{ 
    z-index: 1;
    position: fixed;
    background-color: white;
    height: 65px;
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.logo_sec
{
    display: flex;
    width: 50px;
    margin-right: 20px;
}

#nav_logo
{
    width: 70%;
}

.title_sec
{
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: space-between;
    gap: 10px;
}

.title_sec1
{
    margin-right: 10px;
}

.Nav_links>a
{
    margin-right: 15px;
    text-decoration: none;
    color: black;
}

.title_sec_sub
{
    display: flex;
    justify-content: center;
    align-items: center;
}