.card2_cont
{
    width: 100%;
    height: 100vh;
    position: relative;
}

.card2_sec1
{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.card2_sec1_1
{
    height: 30%;
    background-color: #a7d145;
    width: 100%;
}

.card2_sec1_2
{
    height: 70%;
}

.card2_sec2
{
    position: absolute;
    width: 80%;
    left: 10%;
    margin: auto;
    top: 10%;
    display: flex;
    justify-content: space-between;
}

.card2
{
    height: 650px;
    width: 300px;
    border-top-right-radius: 5%;
    border-top-left-radius: 5%;
    overflow: hidden;
}

.card2_img
{
    width: 100%;
    object-fit: cover;
}

.card2_desc
{
    color: black;
    width: 90%;
    margin: auto;
}